import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipBrIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="br_en"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-TKNF55F"
    ></IndexPage>
  );
};

export default CitizenshipBrIndexPage;
